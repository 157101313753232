<template>
  <div class="optometryHome">
    <div>
      <ul>
        <li @click="go('/training')">
          <img src="@/assets/img/conrea/contact_introduction.png" alt="">
          <p>训练治疗中心</p>
        </li>
        <li @click="go('/frameWithHome')">
          <img src="@/assets/img/conrea/contact_demo.png" alt="">
          <p>框架验配中心</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "optometryHome",
  methods: {
    go(url) {
      this.$router.push({
        path: url
      })
    }
  }
}
</script>

<style scoped lang="scss">
.optometryHome {
  width: 100vw;
  height: 87vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
h3 {padding: .5rem 0;}
ul {
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    padding: 0 .6rem;
    cursor: pointer;
    img {
      display: block;
      width: 160PX;
      margin-bottom: .2rem;
      @media (max-width: 1200px) {
        width: 130px;
      }
    }
  }
}
.pd-4 {padding-top: 4vh}
</style>
